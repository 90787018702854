var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "contents" } },
    _vm._l(_vm.fields, function (field, index) {
      return _c(
        "td",
        {
          staticClass: "Tableee-col",
          class: { "u-textRight": field.isNumber },
          style: field.style || "",
          attrs: { id: `on-hand-table-body-${_vm.rowIndex}-col-${index}` },
        },
        [
          field.type === "currency"
            ? _c("span", { staticClass: "ff-mono u-textRight" }, [
                _vm._v(
                  _vm._s(_vm._f("formatCurrency")(_vm.stockItem[field.name]))
                ),
              ])
            : field.type === "mono" && !field.will_danger_class
            ? _c("span", { staticClass: "u-textRight" }, [
                _vm._v(_vm._s(_vm.stockItem[field.name])),
              ])
            : field.type === "mono" && field.will_danger_class
            ? _c(
                "span",
                {
                  staticClass: "u-textRight",
                  class: {
                    "text-is-danger": _vm.stockItem.stockItemUnitQuantity < 0,
                  },
                },
                [_vm._v(_vm._s(_vm.stockItem[field.name]))]
              )
            : field.type === "number"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.stockItem[field.name],
                    expression: "stockItem[field.name]",
                    modifiers: { number: true },
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      min_value: 0,
                      max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                    },
                    expression:
                      "{min_value: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                  },
                ],
                staticClass: "txt u-textRight no-spinners",
                class: {
                  "is-danger": _vm.veeErrors.has(`${field.name}${index}`),
                },
                attrs: {
                  name: `${field.name}${index}`,
                  id: `${field.name}${_vm.rowIndex}-${index}`,
                  autocomplete: "off",
                  type: "number",
                  step: "any",
                },
                domProps: { value: _vm.stockItem[field.name] },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.stockItem,
                        field.name,
                        _vm._n($event.target.value)
                      )
                    },
                    function ($event) {
                      return _vm.roundInputValue(_vm.stockItem, field.name)
                    },
                  ],
                  change: function ($event) {
                    return _vm.onChangeInput(_vm.stockItem)
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              })
            : field.type === "calculate"
            ? _c("span", { staticClass: "u-textRight" }, [
                _vm._v(
                  _vm._s(
                    _vm.stockItem[field.from] > 0 &&
                      _vm.stockItem[field.from] - _vm.stockItem[field.to] > -1
                      ? Number(
                          Math.abs(
                            _vm.stockItem[field.from] - _vm.stockItem[field.to]
                          ).toFixed(3)
                        )
                      : "-"
                  )
                ),
              ])
            : field.type === "selectbox" &&
              field.fieldSelectData === "requestTypes"
            ? _c(
                "div",
                { staticClass: "select-box" },
                [
                  _c("customSelectInput", {
                    staticClass: "custom-select",
                    attrs: {
                      name: "requestType",
                      optionData: _vm.requestTypes,
                      optionKey: "requestType",
                      optionIdKey: "requestType",
                      selectedDataRequired: true,
                      isValueNumber: true,
                      extraDataEmit: _vm.stockItem,
                      hideDefault: false,
                      optionIdName: "stockOnHand-request-type",
                    },
                    on: { change: _vm.onInputSelected },
                    model: {
                      value: _vm.formItem.requestType,
                      callback: function ($$v) {
                        _vm.$set(_vm.formItem, "requestType", $$v)
                      },
                      expression: "formItem.requestType",
                    },
                  }),
                ],
                1
              )
            : field.type === "selectbox" &&
              field.fieldSelectData === "storeList"
            ? _c(
                "div",
                { staticClass: "select-box" },
                [
                  _c("customSelectInput", {
                    staticClass: "custom-select",
                    attrs: {
                      name: "selectStore",
                      optionData: _vm.storeList,
                      optionKey: "id",
                      optionIdKey: "id",
                      selectedDataRequired: true,
                      extraDataEmit: _vm.stockItem,
                      disabled: _vm.formItem.requestType !== 2,
                      hideDefault: false,
                      optionIdName: "stockOnHand-store",
                    },
                    on: { change: _vm.onInputSelected },
                    model: {
                      value: _vm.formItem.fromStoreId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formItem, "fromStoreId", $$v)
                      },
                      expression: "formItem.fromStoreId",
                    },
                  }),
                ],
                1
              )
            : field.type === "portal" && field.name === "isUsed"
            ? _c(
                "span",
                { staticClass: "tooltip-relative" },
                [
                  _c("CustomMultiselect", {
                    staticClass: "m-left-5",
                    attrs: {
                      id: "onHand-add-shelf",
                      items: _vm.ShelfStorageList,
                      selected: _vm.selectedShelfList,
                      useCustomEmptyState: true,
                      customEmptyState: _vm.stockItem.isUsed
                        ? _vm.$t("OnHand.shelfList_selected")
                        : _vm.$t("OnHand.shelfList_unselected"),
                      popupHeader: _vm.$t("OnHand.shelfStorageList_selection"),
                      unselectedText: _vm.$t(
                        "OnHand.shelfStorageList_selection"
                      ),
                      buttonText: _vm.$t("Global.apply"),
                      buttonIconLeft: "btn-success",
                      showAll: false,
                      isDisabled: !_vm.checkPermission(
                        "ShelfStorageLocationEdit"
                      ),
                      isLoadingData: _vm.fetchingShelfListData,
                      dontDisableButton: _vm.ShelfStorageList.length
                        ? true
                        : false,
                    },
                    on: {
                      onClick: function ($event) {
                        return _vm.getShelfData(_vm.stockItem)
                      },
                      delegateCheckItem: _vm.onChangeSelectShelfList,
                      delegateSubmitItems: function ($event) {
                        return _vm.submitShelfList(_vm.stockItem)
                      },
                    },
                  }),
                  !_vm.checkPermission("ShelfStorageLocationEdit")
                    ? _c("Tooltip", {
                        staticClass: "small",
                        attrs: {
                          white: "",
                          right: "",
                          text: _vm.$t("OnHand.noPermissionForShelfListEdit"),
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "span",
                { staticClass: "tooltip-relative" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.getStockFieldShortName(_vm.stockItem[field.name])
                    )
                  ),
                  _vm.stockItem[field.name].length > 30
                    ? _c("Tooltip", {
                        attrs: {
                          white: "",
                          bottom: "",
                          text: _vm.stockItem[field.name],
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }