import Index from '../'
import StockItemsExpDate from '../expiration-date'

export default [
  {
    path: '/onhand',
    name: 'OnHand',
    component: Index,
    meta: {
      main_menu: true,
      slug: 'onhand',
      page: 'stockonhand',
      category: 'Stock',
      permissionKey: 'StockOnHandMenu'
    }
  },
  {
    path: '/onhand/stock-items-expiration-dates',
    name: 'StockItemsExpDate',
    component: StockItemsExpDate,
    meta: {
      page: 'stockonhand',
      permissionKey: 'StockOnHandMenu'
    }
  }
]
