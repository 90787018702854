export default function getBatchFields($t, showStockItemName) {
  return [
    {
      name: 'daysLeft',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Despatches.daysLeft')
      },
      get title() {
        return $t('Despatches.daysLeft')
      }
    },
    {
      name: 'stockItemName',
      is_visible: showStockItemName ? true : false,
      get short_title() {
        return $t('Despatches.stockItemName')
      },
      get title() {
        return $t('Despatches.stockItemName')
      }
    },
    {
      name: 'quantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_Quantity')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_Quantity')
      }
    },
    {
      name: 'unitCode',
      is_visible: true,
      get short_title() {
        return $t('Despatches.unitCode')
      },
      get title() {
        return $t('Despatches.unitCode')
      }
    },
    {
      name: 'expirationDate',
      is_visible: true,
      type: 'date',
      get short_title() {
        return $t('Despatches.expirationDate')
      },
      get title() {
        return $t('Despatches.expirationDate')
      }
    },
    {
      name: 'degree',
      is_visible: true,
      get short_title() {
        return $t('Despatches.degree')
      },
      get title() {
        return $t('Despatches.degree')
      }
    },
    {
      name: 'shipmentTime',
      is_visible: true,
      get short_title() {
        return $t('Despatches.shipmentTime')
      },
      get title() {
        return $t('Despatches.shipmentTime')
      }
    },
    {
      name: 'warehouseTime',
      is_visible: true,
      get short_title() {
        return $t('Despatches.warehouseTime')
      },
      get title() {
        return $t('Despatches.warehouseTime')
      }
    },
    {
      name: 'lotNumber',
      is_visible: true,
      get short_title() {
        return $t('Despatches.lotNumber')
      },
      get title() {
        return $t('Despatches.lotNumber')
      }
    },
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('Despatches.storeName')
      },
      get title() {
        return $t('Despatches.storeName')
      }
    },
    {
      name: 'shelfStorageLocationListHeaderName',
      is_visible: true,
      get short_title() {
        return $t('Despatches.shelf')
      },
      get title() {
        return $t('Despatches.shelf')
      }
    }
  ]
}
