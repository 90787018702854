import BaseClass from '@/utils/baseclass'

export class Suggestions extends BaseClass {
  addItem(item) {
    let newItem = new Suggestion(item)
    this.list.push(newItem)
  }
}

class Suggestion {
  constructor(item) {
    this.id = item.id
    this.name = item.name
    this.type = item.type
  }

  get typeKey() {
    switch (this.type) {
      case 0: return 'majorGroup'
      case 1: return 'mainGroup'
      case 2: return 'itemGroup'
      case 3: return 'stockItem'
    }
  }
}

export class Stocks extends BaseClass {
  addItem(item) {
    let newItem = new StockItem(item)
    this.list.push(newItem)
  }
}

class StockItem {
  constructor(item) {
    this.stockItemLastPurchasePrice = item.stockItemLastPurchasePrice
    this.stockItemName = item.stockItemName
    this.stockItemGroupName = item.itemGroupName
    this.stockItemTotalAmount = item.stockItemTotalAmount
    this.stockItemUnitCode = item.stockItemUnitCode
    this.stockItemUnitPrice = item.stockItemUnitPrice
    this.stockItemUnitId = item.stockItemUnitId
    this.stockItemUnitQuantity = item.stockItemUnitQuantity
    this.transitStockItemUnitQuantity = item.transitStockItemUnitQuantity
    this.stockItemBaseUnitCode = item.stockItemBaseUnitCode
    this.stockItemBaseUnitQuantity = item.stockItemBaseUnitQuantity
    this.transitStockItemBaseUnitQuantity = item.transitStockItemBaseUnitQuantity
    this.stockItemBaseUnitPrice = item.stockItemBaseUnitPrice
    this.storeName = item.storeName
    this.stockItemMinimumQuantity = item.stockItemMinimumQuantity
    this.stockItemOptimumQuantity = item.stockItemOptimumQuantity
    this.stockItemId = item.stockItemId
    this.storeId = item.storeId
    this.requestType = item.requestType
    this.fromStoreId = item.fromStoreId
    this.stockItemSafeStockQuantity = item.stockItemSafeStockQuantity
    this.isUsed = item.isUsed
    this.expirationDateControlIsEnabled = item.expirationDateControlIsEnabled
    this.currencyDefinitionId = item.currencyDefinitionId
    this.currencyDefinitionSymbol = item.currencyDefinitionSymbol
  }
}
