<template lang="pug">
  div(
    style="display:contents"
  )
    td.Tableee-col(
      v-for="(field, index) in fields"
      :class= "{'u-textRight': field.isNumber}"
      :id="`on-hand-table-body-${rowIndex}-col-${index}`"
      :style="field.style || ''"
    )
      span.ff-mono.u-textRight(v-if="field.type === 'currency'") {{ stockItem[field.name] | formatCurrency}}

      span.u-textRight(v-else-if="field.type === 'mono' && !field.will_danger_class") {{ stockItem[field.name] }}

      span.u-textRight(
        :class="{ 'text-is-danger': stockItem.stockItemUnitQuantity < 0 }",
        v-else-if="field.type === 'mono' && field.will_danger_class") {{ stockItem[field.name] }}

      input.txt.u-textRight.no-spinners(
        v-else-if="field.type === 'number'"
        :name="`${field.name}${index}`"
        :id="`${field.name}${rowIndex}-${index}`"
        autocomplete="off"
        v-model.number="stockItem[field.name]"
        type="number"
        step="any"
        @input="roundInputValue(stockItem, field.name)"
        v-validate="{min_value: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
        :class="{ 'is-danger': veeErrors.has(`${field.name}${index}`) }"
        @change="onChangeInput(stockItem)")

      span.u-textRight(
        v-else-if="field.type === 'calculate'"
      ) {{ stockItem[field.from] > 0 && stockItem[field.from] - stockItem[field.to] > -1 ? Number(Math.abs(stockItem[field.from] - stockItem[field.to]).toFixed(3)) : "-" }}

      .select-box(v-else-if="field.type === 'selectbox' && field.fieldSelectData === 'requestTypes'")
        customSelectInput.custom-select(
          name="requestType",
          :optionData="requestTypes"
          v-model="formItem.requestType",
          optionKey="requestType"
          optionIdKey="requestType"
          :selectedDataRequired="true"
          :isValueNumber="true"
          :extraDataEmit="stockItem"
          @change="onInputSelected"
          :hideDefault="false"
          optionIdName="stockOnHand-request-type"
        )
      .select-box(v-else-if="field.type === 'selectbox' && field.fieldSelectData === 'storeList'")
        customSelectInput.custom-select(
          name="selectStore",
          :optionData="storeList"
          v-model="formItem.fromStoreId"
          optionKey="id"
          optionIdKey="id"
          :selectedDataRequired="true"
          :extraDataEmit="stockItem"
          @change="onInputSelected"
          :disabled="formItem.requestType !== 2"
          :hideDefault="false"
          optionIdName="stockOnHand-store"
        )
      span(v-else-if="field.type === 'portal' && field.name === 'isUsed'").tooltip-relative
        //- CustomMultiselect.m-left-5(
        //-   id="onHand-add-shelf"
        //-   :items="ShelfStorageList"
        //-   :selected="selectedShelfList"
        //-   :useCustomEmptyState="true"
        //-   :customEmptyState="stockItem.isUsed ? $t('OnHand.shelfList_selected') : $t('OnHand.shelfList_unselected')"
        //-   :popupHeader="$t('OnHand.shelfStorageList_selection')"
        //-   :unselectedText="$t('OnHand.shelfStorageList_selection')"
        //-   :buttonText="$t('Global.apply')"
        //-   buttonIconLeft="btn-success"
        //-   :showAll="false"
        //-   :isDisabled="!checkPermission('ShelfStorageLocationEdit')"
        //-   :useCustomLocation="(stocks.length > 5 && (stocks.length < rowIndex + 5)) ? true : false"
        //-   :isLoadingData="fetchingShelfListData"
        //-   :dontDisableButton="ShelfStorageList.length ? true : false"
        //-   @onClick="getShelfData(stockItem)"
        //-   @delegateCheckItem="onChangeSelectShelfList"
        //-   @delegateSubmitItems="submitShelfList(stockItem)"
        //- )
        CustomMultiselect.m-left-5(
          id="onHand-add-shelf"
          :items="ShelfStorageList"
          :selected="selectedShelfList"
          :useCustomEmptyState="true"
          :customEmptyState="stockItem.isUsed ? $t('OnHand.shelfList_selected') : $t('OnHand.shelfList_unselected')"
          :popupHeader="$t('OnHand.shelfStorageList_selection')"
          :unselectedText="$t('OnHand.shelfStorageList_selection')"
          :buttonText="$t('Global.apply')"
          buttonIconLeft="btn-success"
          :showAll="false"
          :isDisabled="!checkPermission('ShelfStorageLocationEdit')"
          :isLoadingData="fetchingShelfListData"
          :dontDisableButton="ShelfStorageList.length ? true : false"
          @onClick="getShelfData(stockItem)"
          @delegateCheckItem="onChangeSelectShelfList"
          @delegateSubmitItems="submitShelfList(stockItem)"
        )
        Tooltip.small(
          v-if="!checkPermission('ShelfStorageLocationEdit')"
          white,
          right,
          :text="$t('OnHand.noPermissionForShelfListEdit')")    

      span.tooltip-relative(v-else) {{ getStockFieldShortName(stockItem[field.name]) }}
        Tooltip(
          v-if="stockItem[field.name].length > 30"
          white,
          bottom,
          :text="stockItem[field.name]"
        )
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import customSelect from '@/view/global/custom-select'
import { getObjectValuesByKey } from '@/utils/baseOperations'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'table-detail',
  data () {
    return {
      formItem: {
        storeId: null,
        stockItemId: null,
        stockItemOptimumQuantity: null,
        stockItemMinimumQuantity: null,
        requestType: null,
        fromStoreId: null,
        stockItemSafeStockQuantity: null
      },
      selectedShelfList: [],
      requestTypes: [
        {
          name: this.$t('OnHand.requestType_order'),
          requestType: 1
        },
        {
          name: this.$t('OnHand.requestType_transfer'),
          requestType: 2
        }
      ]
    }
  },
  props: {
    stockItem: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    rowIndex: {
      type: Number,
      required: true
    },
    storeList: {
      type: Array,
      required: true
    }
  },
  components: {
    customSelect,
  },
  mounted () {
    this.setData(this.stockItem)
  },
  computed: {
    ...mapGetters('OnHand', [
      'ShelfStorageList',
      'stocks'
    ]),

    ...mapGetters('Settings', [
      'checkPermission'
    ]),

    fetchingShelfListData() {
      return this.$wait.is('isFetchingShelfLocationData')
    }
  },
  methods: {
    ...mapActions('OnHand', {
      setStoreItem: 'set_STORE_ITEM',
      getShelfStorageLocationList: 'getShelfLocationList',
      updateOnHandShelfStorageList: 'updateOnHandShelfStorageList'
    }),
     ...mapActions('Notify', ['notifyShow']),

     ...mapMutations('OnHand', ['SET_SHELF_LOCATION_LIST']),

    getShelfData: vueWaitLoader(async function(stockItem) {
      const payload = {
        storeId: stockItem.storeId,
        stockItemId: stockItem.stockItemId,
        unitId: stockItem.stockItemUnitId
      }
      await this.getShelfStorageLocationList(payload).then(res => {
        if (res) {
          this.ShelfStorageList.map(i => {
            if (i.isUsed) this.selectedShelfList.push(i)
          })
        }
      })
    },'isFetchingShelfLocationData'),

    onChangeSelectShelfList (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.selectedShelfList = [...this.ShelfStorageList]
        } else {
          this.selectedShelfList.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.selectedShelfList = []
        } else {
          this.selectedShelfList.splice(this.selectedShelfList.indexOf(event.item), 1)
        }
      }
    },

    async submitShelfList(item) {
      const payload = {
        shelfStorageLocationListHeaderIds: this.selectedShelfList.map(i => i.id),
        storeId: item.storeId,
        stockItemId: item.stockItemId,
        unitId: item.stockItemUnitId
      }
      await this.updateOnHandShelfStorageList(payload).then(res => {
        const message = this.$t('OnHand.shelfUpdated_successfully')
        if (res) {
          this.notifyShow({ message })
          this.$emit('getList')
        }
      })
    },

    roundInputValue (item, fieldName) {
      const reg = /\d\.\d/
      const value = fieldName === 'stockItemMinimumQuantity' ? item.stockItemMinimumQuantity : item.stockItemOptimumQuantity
      const roundedValue = Number(value).toFixed(3).toString()
      const lengthDiff = value.length - roundedValue.length
      if (reg.test(value)) {
        if (lengthDiff > 0) {
          if (fieldName === 'stockItemMinimumQuantity') {
            item.stockItemMinimumQuantity = item.stockItemMinimumQuantity.slice(0, roundedValue.length)
          } else {
            item.stockItemOptimumQuantity = item.stockItemOptimumQuantity.slice(0, roundedValue.length)
          }
        }
      }
    },

    setData (item) {
      this.formItem.storeId = item.storeId
      this.formItem.stockItemId = item.stockItemId
      this.formItem.stockItemOptimumQuantity = item.stockItemOptimumQuantity || null
      this.formItem.stockItemMinimumQuantity = item.stockItemMinimumQuantity || null
      this.formItem.stockItemSafeStockQuantity = item.stockItemSafeStockQuantity || null
      if (item.requestType) this.formItem.requestType = item.requestType
      if (item.id) this.formItem.fromStoreId = item.id || null
      if (item.fromStoreId) this.formItem.fromStoreId = item.fromStoreId || null
    },

    onInputSelected (item) {
      if (item.selectedItem === null || typeof item.selectedItem === 'undefined') {
        this.formItem.fromStoreId = null
        this.formItem.requestType = 0
        this.setOnHandStockItems()
        return
      }
      if (item.selectedItem.requestType) item.extraDataEmit.requestType = 0
      if (item.selectedItem.id) item.extraDataEmit.id = item.selectedItem.id
      const setFormData = getObjectValuesByKey(item.extraDataEmit, ['storeId', 'stockItemId', 'stockItemOptimumQuantity', 'stockItemMinimumQuantity', 'requestType', 'id'])
      this.setData(setFormData)
      if (this.formItem.requestType === 2 && this.formItem.fromStoreId) {
        this.setOnHandStockItems()
      } else if (this.formItem.requestType === 1) {
        this.formItem.fromStoreId = null
        this.setOnHandStockItems()
      }
    },

    onChangeInput (item) {
      this.$validator.validateAll().then(async result => {
        if (!result) return
        await this.setData(item)
        this.setOnHandStockItems()
      })
    },

    getStockFieldShortName (name) {
      return name.length > 30 ? name.substr(0, 30).concat('...') : name
    },

    setOnHandStockItems () {
      const payload = {
        storeId: this.formItem.storeId,
        stockItemId: this.formItem.stockItemId,
        optimumQuantity: this.formItem.stockItemOptimumQuantity || null,
        minimumQuantity: this.formItem.stockItemMinimumQuantity || null,
        fromStoreId: this.formItem.fromStoreId,
        safeStockQuantity: this.formItem.stockItemSafeStockQuantity || null
      }
      if (this.formItem.requestType) payload.requestType = this.formItem.requestType
      this.setStoreItem(payload)
    },

    selectedItem (stockItem, field) {
      let comparedItem = null
      if (field === 'requestTypes') comparedItem = this.requestTypes.find(item => item.requestType === stockItem.requestType)
      else comparedItem = this.storeList.find(item => item.id === stockItem.fromStoreId)
      if (comparedItem) return comparedItem
    }
  },
  watch: {
    stockItem () {
      this.setData(this.stockItem)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~@/stylesheet/config/mixin";

  .Tableee-col {
    vertical-align: middle;
  }
  :deep() .request-type-selectbox .select-dropdown-custom-selected {
    width: 100% !important;
  }
  :deep() .request-type-selectbox .select-dropdown-custom-options {
    width: 100% !important;
  }

  :deep() .transfer-type-selectbox .select-dropdown-custom-selected {
    width: 150px !important;
  }
  :deep() .transfer-type-selectbox .select-dropdown-custom-options {
    width: 150px !important;
  }
  :deep() .select {
    width: 150px;
    height: 39px;
  }
  :deep() .MiniPopup.left::before {
    display: none !important;
  }
  :deep() .MiniPopup.left::after {
    display: none !important;
  }
  :deep()  .MiniPopup:not(.left):not(.right)::before {
    display: none !important;
  }
  :deep()  .MiniPopup:not(.left):not(.right)::after {
    display: none !important;
  }
  :deep() .dropdown-handle-button.has-values {
    color: $color-dark !important;
  }
  :deep() .dropdown-handle-button-values {
    font-weight: $font-weight-normal !important;
    font-size: $font-size-normal !important;
  }

  :deep() .popup-content {
    overflow-y: scroll !important;
  }

</style>
