import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { Suggestions, Stocks } from '../model'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,

  state: {
    suggestions: new Suggestions(),
    totalAmount: 0,
    totalQuantity: 0,
    stocks: new Stocks(),
    stockItemsWithExpDate: [],
    stocksShortList: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
    pageHeaderLinks: [
      'OnHand',
      'StockReports',
      'StockItemsExpDate'
    ],
    shelfStorageList: []
  },

  getters: {
    pageHeaderLinks: state => state.pageHeaderLinks,
    suggestions: state => state.suggestions.list,
    suggestionsIsEmpty: state => state.suggestions.list.length === 0,
    totalAmount: state => state.totalAmount,
    totalQuantity: state => state.totalQuantity,
    stocks: state => state.stocks.list,
    stockItemsWithExpDate: state => state.stockItemsWithExpDate,
    stocksShortList: state => state.stocksShortList,
    stocksIsEmpty: state => state.stocks.list.length === 0,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    }),
    ShelfStorageList: state => state.shelfStorageList
  },

  actions: {
    async search_Suggestion({ state, commit, dispatch }, Name) {
      const results = await requestHandler({
        title: 'search_Suggestion',
        methodName: 'search_Suggestion',
        method: () => {
          return HTTP.get('StockOnHand/searchbyname', {
            params: { Name }
          })
        },
        success: result => {
          commit('UPDATE_SUGGESTIONS', result.data)
          if (result.data.stockOnHandFilterParams.length === 0) {
            commit('remove_STOCKS')
          }
          return result
        }
      })
      return results
    },

    async set_STORE_ITEM({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'set_STORE_ITEM',
        methodName: 'set_STORE_ITEM',
        method: () => {
          return HTTP.post('StoreItem', payload)
        },
        success: result => result
      })
      return results
    },

    async get_STOCKS_ONHAND({ state, commit }, {
      item,
      storeItemFilterType = -1,
      storeIds = null,
      page = 1,
      stockItemType = null,
      overGroupIds = null,
      stockItemIds = null,
      majorGroupIds = null,
      itemGroupIds = null,
      showExpirationDateControlIsEnabledOnly = false,
      pageSize,
      sortExpressionList = [],
      showWithBaseCurrency
    }) {
      const PAGE_NUMBER = getPaginationNumber({ selectedPage: page, currentPage: state.Page.number })

      /**
       * all: -1,
       * OverGroup: 0,
       * MajorGroup: 1,
       * ItemGroup: 2,
       * StockItem: 3
       */

      let params = {
        storeItemFilterType,
        storeIds,
        overGroupIds,
        majorGroupIds,
        itemGroupIds,
        stockItemIds,
        stockItemType,
        showExpirationDateControlIsEnabledOnly,
        sortExpressionList,
        PageSize: pageSize,
        PageNumber: PAGE_NUMBER,
        showWithBaseCurrency: showWithBaseCurrency
      }

      let singularSearch = {
        storeItemFilterType,
        showExpirationDateControlIsEnabledOnly,
        storeIds,
        overGroupIds: null,
        majorGroupIds: null,
        itemGroupIds: null,
        stockItemIds: null,
        stockItemType,
        PageSize: pageSize,
        PageNumber: PAGE_NUMBER,
        showWithBaseCurrency: showWithBaseCurrency
      }

      if (item) {
        switch (item.type) {
          case 0:
            singularSearch.overGroupIds = [item.id]
            break
          case 1:
            singularSearch.majorGroupIds = [item.id]
            break
          case 2:
            singularSearch.itemGroupIds = [item.id]
            break
          case 3:
            singularSearch.stockItemIds = [item.id]
            break
        }
      }

      const results = await requestHandler({
        title: 'get_STOCKS_ONHAND',
        methodName: 'get_STOCKS_ONHAND',
        method: () => {
          return HTTP.post('StockOnHand', item ? singularSearch : params)
        },
        success: result => {
          commit('RESET_PAGE')
          commit('UPDATE_STOCKS', result.data)
          return result
        }
      })
      return results
    },

    async getStockOnHandShortList({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getStockOnHandShortList',
        methodName: 'getStockOnHandShortList',
        method: () => {
          return HTTP.post('StockOnHand/all', payload)
        },
        success: result => {
          commit('set_STOCK_SHORT_ITEMS', result.data.stockItemList)
          return result
        }
      })
      return results
    },

    async getStockOnHandListForTransferSalePrice({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getStockOnHandListForTransferSalePrice',
        methodName: 'getStockOnHandListForTransferSalePrice',
        method: () => {
          return HTTP.post('StockOnHand/withtransfersalespricelistusagestatus', payload)
        },
        success: result => {
          return result
        }
      })
      return results
    },

    async getShelfLocationList({ state, commit, dispatch}, payload) {
      const results = await requestHandler({
        title: 'getShelfLocationList',
        methodName: 'getShelfLocationList',
        method: () => {
          return HTTP.post('ShelfStorageLocationList/header/allbystore', payload)
        },
        success: result => {
          commit('SET_SHELF_LOCATION_LIST', result.data)
          return result
        }
      })
      return results
    },

    async getStockItemsWithExpDate ({ state, commit, dispatch}, payload) {
      const results = await requestHandler({
        title: 'getStockItemsWithExpDate',
        methodName: 'getStockItemsWithExpDate',
        method: () => {
          return HTTP.post('Batch/all/bystoresandstockitems', payload)
        },
        success: result => {
          commit('SET_EXP_DATE_STOCK_ITEM_LIST', result.data)
          return result
        }
      })
      return results
    },

    async updateOnHandShelfStorageList({ state, commit, dispatch}, payload) {
      const results = await requestHandler({
        title: 'updateOnHandShelfStorageList',
        methodName: 'updateOnHandShelfStorageList',
        method: () => {
          return HTTP.put('ShelfStorageLocationList/detailbystore', payload)
        },
        success: result => result
      })
      return results
    }
    
  },

  mutations: {
    RESET(state) {
      state.suggestions = new Suggestions()
      state.totalAmount = 0
      state.totalQuantity = 0
      state.stocks = new Stocks()
      state.Page = {
        number: 1,
        size: 20,
        total: 0
      }
    },

    RESET_PAGE(state) {
      state.Page = {
        number: 1,
        size: 20,
        total: 0
      }
    },

    UPDATE_SUGGESTIONS(state, data) {
      state.suggestions.addItems(data.stockOnHandFilterParams)
    },

    remove_SUGGESTIONS(state) {
      state.suggestions.clearList()
    },

    UPDATE_STOCKS(state, data) {
      state.stocks.addItems(data.stockOnHandList)
      state.totalAmount = data.totalAmount
      state.totalQuantity = data.totalQuantity
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },

    remove_STOCKS(state) {
      state.stocks.clearList()
    },
    set_STOCK_SHORT_ITEMS(state, list) {
      state.stocksShortList = list
    },
    SET_SHELF_LOCATION_LIST(state, data) {
      state.shelfStorageList = data.shelfStorageLocationListHeaderListByStore
    },
    SET_EXP_DATE_STOCK_ITEM_LIST(state, data) {
      state.stockItemsWithExpDate = data.batchList
    }
  }
}
