export default function($t, $getConst) {
  return [
    {
      name: 'storeName',
      is_visible: true,
      fixedColumnHeader: true,
      get short_title() {
        return $t('OnHand.tableColumn_StoreName_Short')
      },
      get title() {
        return $t('OnHand.tableColumn_StoreName')
      }
    },
    {
      name: 'stockItemName',
      is_visible: true,
      fixedColumnHeader: true,
      type: 'portal',
      get short_title() {
        return $t('OnHand.tableColumn_StockName_Short')
      },
      get title() {
        return $t('OnHand.tableColumn_StockName')
      },
      is_sortable: true,
      sortingObject: {
        columnName: 'StockItemName',
        sortType: null
      },
      filter: {
        showFilter: false,
        position: {},
        list: [],
        selectedList: [],
        selectedListName: 'selectedStockItems',
        is_visible: true,
        type: null,
        headerLabel: $t('OnHand.Filter_Stock_Item_Label'),
        backendSearch: 'stockItem'
      }
    },
    {
      name: 'stockItemGroupName',
      is_visible: true,
      fixedColumnHeader: true,
      get short_title() {
        return $t('OnHand.tableColumn_ItemGroup_Short')
      },
      get title() {
        return $t('OnHand.tableColumn_ItemGroup')
      },
      is_sortable: true,
      sortingObject: {
        columnName: 'ItemGroupName',
        sortType: null
      },
      filter: {
        showFilter: false,
        position: {},
        list: [],
        selectedList: [],
        selectedListName: 'selectedItemGroups',
        is_visible: true,
        type: null,
        headerLabel: $t('OnHand.Filter_Alt_Groups_Label')
      }
    },
    {
      name: 'stockItemBaseUnitQuantity',
      is_visible: true,
      isNumber: true,
      type: 'mono',
      will_danger_class: true,
      get short_title() {
        return $t('OnHand.tableColumn_BaseUnitQuantity_Short')
      },
      get title() {
        return $t('OnHand.tableColumn_BaseUnitQuantity')
      }
    },
    {
      name: 'transitStockItemBaseUnitQuantity',
      is_visible: true,
      type: 'mono',
      isNumber: true,
      will_danger_class: true,
      get short_title() {
        return $t('OnHand.tableColumn_TransitBaseUnitQuantity_Short')
      },
      get title() {
        return $t('OnHand.tableColumn_TransitBaseUnitQuantity')
      }
    },
    {
      name: 'stockItemBaseUnitCode',
      is_visible: true,
      type: null,
      get short_title() {
        return $t('OnHand.tableColumn_BaseUnitCode_Short')
      },
      get title() {
        return $t('OnHand.tableColumn_BaseUnitCode')
      }
    },
    {
      name: 'stockItemBaseUnitPrice',
      is_visible: true,
      isNumber: true,
      type: 'currency',
      get short_title() {
        return $t('OnHand.tableColumn_BaseUnitPrice_Short')
      },
      get title() {
        return $t('OnHand.tableColumn_BaseUnitPrice')
      }
    },
    {
      name: 'stockItemUnitQuantity',
      is_visible: true,
      isNumber: true,
      type: 'mono',
      will_danger_class: true,
      get short_title() {
        return $t('OnHand.tableColumn_UnitQuantity_Short')
      },
      get title() {
        return $t('OnHand.tableColumn_UnitQuantity')
      }
    },
    {
      name: 'transitStockItemUnitQuantity',
      is_visible: true,
      isNumber: true,
      type: 'mono',
      will_danger_class: true,
      get short_title() {
        return $t('OnHand.tableColumn_TransitUnitQuantity_Short')
      },
      get title() {
        return $t('OnHand.tableColumn_TransitUnitQuantity')
      }
    },
    {
      name: 'stockItemUnitCode',
      is_visible: true,
      type: null,
      get short_title() {
        return $t('OnHand.tableColumn_UnitCode_Short')
      },
      get title() {
        return $t('OnHand.tableColumn_UnitCode')
      }
    },
    {
      name: 'stockItemUnitPrice',
      is_visible: true,
      isNumber: true,
      type: 'currency',
      get short_title() {
        return $t('OnHand.tableColumn_UnitPrice')
      },
      get title() {
        return $t('OnHand.tableColumn_UnitPrice')
      }
    },
    {
      name: 'stockItemMinimumQuantity',
      is_visible: true,
      style: 'padding-top: 5px; padding-bottom: 5px;',
      type: 'portal',
      get short_title() {
        return $t('OnHand.tableColumn_MinimumQuantity')
      },
      get title() {
        return $t('OnHand.tableColumn_MinimumQuantity')
      }
    },
    {
      name: 'stockItemOptimumQuantity',
      style: 'padding-top: 5px; padding-bottom: 5px;',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('OnHand.tableColumn_OptimumQuantity')
      },
      get title() {
        return $t('OnHand.tableColumn_OptimumQuantity')
      }
    },
    {
      name: 'orderByMinimumStock',
      is_visible: true,
      type: 'portal',
      to: 'stockItemUnitQuantity',
      from: 'stockItemMinimumQuantity',
      get short_title() {
        return $t('OnHand.tableColumn_OrderByMinimumStock')
      },
      get title() {
        return $t('OnHand.tableColumn_OrderByMinimumStock')
      }
    },
    {
      name: 'orderByOptimumStock',
      is_visible: true,
      type: 'portal',
      to: 'stockItemUnitQuantity',
      from: 'stockItemOptimumQuantity',
      get short_title() {
        return $t('OnHand.tableColumn_OrderByOptimumStock')
      },
      get title() {
        return $t('OnHand.tableColumn_OrderByOptimumStock')
      }
    },
    {
      name: 'requestType',
      is_visible: true,
      fieldSelectData: 'requestTypes',
      type: 'portal',
      get short_title() {
        return $t('OnHand.tableColumn_stockItemType')
      },
      get title() {
        return $t('OnHand.tableColumn_stockItemType')
      }
    },
    {
      name: 'fromStoreId',
      is_visible: true,
      type: 'portal',
      fieldSelectData: 'storeList',
      get short_title() {
        return $t('OnHand.tableColumn_storeId')
      },
      get title() {
        return $t('OnHand.tableColumn_storeId')
      }
    },
    {
      name: 'isUsed',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('OnHand.tableColumn_shelfStorageLocation')
      },
      get title() {
        return $t('OnHand.tableColumn_shelfStorageLocation')
      }
    },    
    {
      name: 'stockItemLastPurchasePrice',
      is_visible: true,
      isNumber: true,
      type: 'currency',
      get short_title() {
        return $t('OnHand.tableColumn_LastPurchasePrice')
      },
      get title() {
        return $t('OnHand.tableColumn_LastPurchasePrice')
      }
    },
    {
      name: 'stockItemSafeStockQuantity',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('StockOnHand.tableColumn_StockOnHand_StockItemSafeStockQuantity')
      },
      get title() {
        return $t('StockOnHand.tableColumn_StockOnHand_StockItemSafeStockQuantity')
      }
    },
    {
      name: 'stockItemTotalAmount',
      is_visible: true,
      isNumber: true,
      type: 'currency',
      get short_title() {
        return $t('OnHand.tableColumn_TotalAmount_Short')
      },
      get title() {
        return $t('OnHand.tableColumn_TotalAmount')
      }
    }
  ]
}
